import React from 'react';
import classNames from 'classnames';
import { flowRight, isNumber, get } from 'lodash';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import { useExperiments } from '@wix/yoshi-flow-editor';
import {
  SECTIONS,
  isLayoutNameTextOnImage,
  isLayoutNameList,
  isLayoutNamePGSideBySide,
  isExperimentEnabled,
  resolveId,
  EXPERIMENTS,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_FEED_MORE_ACTIONS_FIX } from '@wix/communities-blog-experiments';

import { TEXT_PLACEMENT } from '../../constants/pro-gallery-options';
import withDeviceType from '../../hoc/with-device-type';
import withFeedBorderWidth from '../../hoc/with-feed-border-width';
import withFeedMetadataSettings from '../../hoc/with-feed-metadata-settings';
import withFontClassName from '../../hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import withPostFontSize from '../../hoc/with-post-font-size';
import {
  getContentAlignment,
  getLayoutDescriptionLineCount,
  getLayoutTitleLineCount,
} from '../../selectors/app-settings-selectors';
import {
  getShouldUsePostListMobileDescriptionFontSize,
  getShouldUsePostListMobileTitleFontSize,
} from '../../selectors/mobile-settings-selectors';
import {
  getPostCoverImageSrc,
  getPostByIdOrSlug,
} from '../../selectors/post-selectors';
import { getContentAlignmentStyles } from '../../services/content-alignment-helpers';
import { getFeedColorClassName } from '../../services/layout-config';
import { getIsRTL } from '../../store/basic-params/basic-params-selectors';
import {
  getPostAverageRating,
  getPostTotalRatings,
} from '../../store/post-ratings/post-ratings-selector';
import alignmentStyles from '../../styles/post-list-item-alignment.scss';
import styles from '../../styles/post-list-item.scss';
import PostLink from '../link/post-link';
import PostFooter from '../post-footer';
import PostListItemCategoryLabel from '../post-list-item-category-label';
import PostListItemHeader from '../post-list-item-header';
import RatingsDisplay, { RatingsDisplayLayout } from '../ratings-display';
import { connect } from '../runtime-context';
import { HorizontalSeparatorForPostCard } from '../separator';
import PostListItemProGalleryExcerpt from './post-list-item-pro-gallery-excerpt';
import PostListItemProGalleryTitle from './post-list-item-pro-gallery-title';

const getMetadataStyle = memoizeOne((postMetadataFontSize) => ({
  fontSize: postMetadataFontSize,
}));
const getTitleStyle = memoizeOne((postTitleFontSize) => ({
  fontSize: postTitleFontSize,
}));
const getDescriptionStyle = memoizeOne((postDescriptionFontSize) => ({
  fontSize: postDescriptionFontSize,
}));
const getDefaultPostTitleFontSize = memoizeOne((defaultPostTitleFontSize) => ({
  fontSize: defaultPostTitleFontSize,
}));
const getDefaultDescriptionFontSize = memoizeOne(
  (defaultPostDescriptionFontSize) => ({
    fontSize: defaultPostDescriptionFontSize,
  }),
);
const empty = {};

export const PostListItem = ({
  type,
  alignment,
  post,
  onLikeClick,
  itemConfig,
  titleFontClassName,
  contentFontClassName,
  contentFontClassNameWithStyle,
  postTitleFontSize,
  postDescriptionFontSize,
  postMetadataFontSize,
  isMetadataFooterVisible,
  isMetadataHeaderVisible,
  canSeeMoreButton,
  showPostDescription,
  applyFeedDesign,
  getPostClassName,
  layoutSidesPadding,
  hideAuthorBadge,
  isRTL,
  isFeedMoreActionsFixEnabled,
  galleryOptions = {},
  textBoxAlignment,
  section,
  descriptionLineCount,
  titleLineCount,
  navigateProGalleryWithinPostPage,
  showCategoryLabel,
  shouldUsePostListMobileDescriptionFontSize,
  shouldUsePostListMobileTitleFontSize,
  visibleActions,
  defaultPostTitleFontSize,
  defaultPostDescriptionFontSize,
  showPostRating,
  isMobile,
  postAverageRating,
  postTotalRatings,
}) => {
  const { experiments } = useExperiments();
  const isLiveSiteEditingDeprecated = experiments.enabled(
    EXPERIMENTS.LIVE_SITE_EDITOR_DEPRECATION,
  );
  const isHoverDisabled =
    isLiveSiteEditingDeprecated &&
    section === 'my-posts' &&
    post.status === 'unpublished';

  const showMoreButton = canSeeMoreButton(post);
  const { lineCount, displayFooterIcons } = itemConfig;
  const hasIconsInHeader = showMoreButton || post.isPinned;
  const layoutList = isLayoutNameList(type);
  const isSideBySideLayout = isLayoutNamePGSideBySide(type);
  const withoutFooter = !isMetadataFooterVisible;
  const withDescription = showPostDescription && !layoutList;
  const containerClassName = classNames(
    styles.container,
    styles.proGallery,
    isRTL && styles.isRTL,
    styles[type],
    alignmentStyles[alignment],
    withoutFooter && styles.withoutFooter,
    contentFontClassName,
    'blog-text-color',
    'blog-card-background-color',
    'blog-card-border-color',
    'post-list-item',
    !applyFeedDesign &&
      !isLayoutNameTextOnImage(type) &&
      'blog-hover-container',
    !isLayoutNameTextOnImage(type) && getPostClassName('hover-container'),
    getPostClassName(
      'border-color',
      'post-container',
      getFeedColorClassName(type, 'background-color'),
    ),
  );

  const contentWrapperClassName = classNames(
    styles.contentWrapper,
    styles[type],
    isRTL && alignmentStyles.isRTL,
    !applyFeedDesign && isLayoutNameTextOnImage(type) && styles.withBackground,
    withoutFooter && styles.withoutFooter,
    'post-list-item-wrapper',
    isLayoutNameTextOnImage(type) &&
      getPostClassName('overlay-background-color'),
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
      getFeedColorClassName(type, 'description-fill'),
    ),
  );

  const titleContainer = classNames(
    styles.textWrapper,
    {
      [getPostClassName('link-hashtag-hover-color')]: !isHoverDisabled,
      [styles.fadeout]: descriptionLineCount || titleLineCount,
    },
    styles[type],
    showCategoryLabel && styles.withCategoryLabel,
  );
  const titleLinkClassName = classNames(
    styles.titleWrapper,
    styles.proGallery,
    styles[type],
    showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
    'has-custom-focus',
    styles.customFocus,
  );
  const excerptLinkClassName = classNames(
    styles.excerptLink,
    styles.textWrapper,
    styles.proGallery,
    styles[type],
    showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const titleContainerClassName = classNames(
    styles.title,
    titleFontClassName,
    styles[type],
    withoutFooter && styles.withoutFooter,
    !withDescription && withoutFooter && styles.withoutBottomMargin,
    !withDescription && styles.withoutDescription,
    post.isPinned && styles.withIcons,
    showMoreButton ? styles.withShowMoreButton : styles.withoutShowMoreButton,
  );
  const contentClassName = classNames(
    styles.content,
    styles[type],
    withoutFooter && styles.withoutFooter,
    contentFontClassNameWithStyle,
    getPostClassName('description-style-font'),
    alignmentStyles.content,
  );
  const headerClassName = classNames(
    styles.headerWrapper,
    contentFontClassName,
    getPostClassName('description-font'),
    alignmentStyles.headerContainer,
  );
  const footerClassName = classNames(
    getPostClassName(
      'description-font',
      getFeedColorClassName(type, 'description-color'),
    ),
  );
  const titleStyle = shouldUsePostListMobileTitleFontSize
    ? getTitleStyle(postTitleFontSize)
    : getDefaultPostTitleFontSize(defaultPostTitleFontSize);

  const contentStyle =
    !applyFeedDesign || shouldUsePostListMobileDescriptionFontSize
      ? getDescriptionStyle(postDescriptionFontSize)
      : getDefaultDescriptionFontSize(defaultPostDescriptionFontSize);
  const containerStyle = {
    background:
      getPostCoverImageSrc(post) && isLayoutNameTextOnImage(type)
        ? 'transparent'
        : undefined,
    borderWidth: 0,
    '--wix-blog-inline-padding': isNumber(layoutSidesPadding)
      ? `${layoutSidesPadding}px`
      : undefined,
  };

  if (layoutList) {
    containerStyle.background = 'transparent';
    containerStyle.paddingTop = '12px';
    containerStyle.paddingBottom = '12px';
    textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT
      ? (containerStyle.marginLeft = '14px')
      : (containerStyle.marginRight = '14px');
  }

  const getLayoutPaddingStyles = (
    layoutSidesPadding,
    layoutTextOnImage,
    isFooter = false,
  ) => {
    if (!layoutTextOnImage && isFooter) {
      return empty;
    }

    return isNumber(layoutSidesPadding)
      ? {
          paddingLeft: layoutSidesPadding,
          paddingRight: layoutSidesPadding,
          ...(isFooter && {
            left: 0,
            right: 0,
          }),
        }
      : {};
  };

  const Header = (
    <PostListItemHeader
      className={headerClassName}
      post={post}
      showMoreButton={showMoreButton}
      hideAuthorBadge={hideAuthorBadge}
      showProfileImage={!isLayoutNameTextOnImage(type)}
      style={getMetadataStyle(postMetadataFontSize)}
      type={type}
      isProGallery={true}
      alignment={alignment}
      visibleActions={visibleActions}
    />
  );

  const renderCategoryLabel = () =>
    showCategoryLabel && (
      <PostListItemCategoryLabel
        className={classNames(
          alignmentStyles.categoryLabel,
          styles.categoryLabelWrapper,
        )}
        post={post}
        postListLayout={type}
      />
    );

  // HACK: for side-by-side with borders and no image
  if (!get(post, 'coverImage.shouldRender') && isSideBySideLayout) {
    if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_RIGHT) {
      containerStyle.paddingLeft = `${galleryOptions.itemBorderWidth * 2}px`;
    }
    if (textBoxAlignment === TEXT_PLACEMENT.SHOW_ON_THE_LEFT) {
      containerStyle.paddingRight = `${galleryOptions.itemBorderWidth * 2}px`;
    }
  }

  const renderTitle = () => (
    <div
      style={titleStyle}
      className={classNames(titleContainerClassName, alignmentStyles.textAlign)}
      data-hook="post-list-item__title"
    >
      <PostListItemProGalleryTitle
        type={type}
        title={post.title}
        style={titleStyle}
        lineCount={lineCount.title}
        titleLineCount={titleLineCount}
        showCategoryLabel={showCategoryLabel}
        layoutSidesPadding={layoutSidesPadding}
        isHoverDisabled={isHoverDisabled}
      />
      {!layoutList && renderRatingsDisplay()}
    </div>
  );

  const renderPostDescription = () => (
    <div
      style={contentStyle}
      className={classNames(contentClassName, alignmentStyles.textAlign)}
      data-hook="post-description"
    >
      <PostListItemProGalleryExcerpt
        post={post}
        lineCount={lineCount.description}
        descriptionLineCount={descriptionLineCount}
      />
    </div>
  );

  const renderRatingsDisplay = () =>
    showPostRating && postTotalRatings ? (
      <RatingsDisplay
        className={classNames(
          styles.rating,
          styles[section],
          styles[type],
          alignmentStyles.rating,
          {
            [styles.withoutFooter]: withoutFooter,
            [styles.withTextOnImageStyles]: isLayoutNameTextOnImage(type),
          },
        )}
        rating={postAverageRating}
        count={postTotalRatings}
        layout={
          isMobile
            ? RatingsDisplayLayout.with_range
            : RatingsDisplayLayout.count_only
        }
      />
    ) : null;

  return (
    <article
      className={classNames(containerClassName, styles.forceRelative)}
      style={containerStyle}
      data-hook="post-list-item"
    >
      <div
        style={getLayoutPaddingStyles(
          layoutSidesPadding,
          isLayoutNameTextOnImage(type),
        )}
        className={contentWrapperClassName}
      >
        {!layoutList &&
          ((isFeedMoreActionsFixEnabled && hasIconsInHeader) ||
            isMetadataHeaderVisible) &&
          Header}
        {!layoutList && renderCategoryLabel()}
        <div className={titleContainer}>
          {isHoverDisabled ? (
            renderTitle()
          ) : (
            <PostLink
              postLink={isHoverDisabled ? null : post.link}
              postPath={post.slug}
              className={titleLinkClassName}
            >
              {renderTitle()}
            </PostLink>
          )}
          {withDescription &&
          (lineCount.description || descriptionLineCount) ? (
            isHoverDisabled ? (
              renderPostDescription()
            ) : (
              <div
                className={excerptLinkClassName}
                onClick={() => navigateProGalleryWithinPostPage(post.slug)}
              >
                {renderPostDescription()}
              </div>
            )
          ) : null}
        </div>
        {layoutList && renderCategoryLabel()}
        {layoutList && renderRatingsDisplay()}
        {layoutList && Header}
        {isMetadataFooterVisible && (
          <div
            style={getLayoutPaddingStyles(
              layoutSidesPadding,
              isLayoutNameTextOnImage(type),
              true,
            )}
            className={classNames(
              styles.footer,
              styles[type],
              styles.withoutCover,
              styles[section],
            )}
          >
            {!layoutList && (
              <HorizontalSeparatorForPostCard
                className={classNames(styles.separator, styles[type])}
              />
            )}
            <div style={getMetadataStyle(postMetadataFontSize)}>
              <PostFooter
                type={type}
                post={post}
                onLikeClick={onLikeClick}
                displayIcons={displayFooterIcons}
                className={footerClassName}
                commentClassName={classNames(
                  getPostClassName('link-hashtag-hover-color'),
                )}
              />
            </div>
          </div>
        )}
      </div>
    </article>
  );
};

PostListItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  post: PropTypes.object,
  type: PropTypes.string.isRequired,
  itemConfig: PropTypes.object.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isMetadataHeaderVisible: PropTypes.bool,
  canSeeMoreButton: PropTypes.func.isRequired,
  showPostDescription: PropTypes.bool,
  applyFeedDesign: PropTypes.bool.isRequired,
  section: PropTypes.oneOf(SECTIONS),
  layoutSidesPadding: PropTypes.number,
  hideAuthorBadge: PropTypes.bool,
  isRTL: PropTypes.bool,
  isFeedMoreActionsFixEnabled: PropTypes.bool.isRequired,
  galleryOptions: PropTypes.object,
  textBoxAlignment: PropTypes.string,
  descriptionLineCount: PropTypes.number,
  titleLineCount: PropTypes.number,
  navigateProGalleryWithinPostPage: PropTypes.func.isRequired,
  shouldUsePostListMobileDescriptionFontSize: PropTypes.bool.isRequired,
  shouldUsePostListMobileTitleFontSize: PropTypes.bool.isRequired,
  visibleActions: PropTypes.arrayOf(PropTypes.string),
  defaultPostTitleFontSize: PropTypes.number,
  defaultPostDescriptionFontSize: PropTypes.number,
  showPostRating: PropTypes.bool,
  isMobile: PropTypes.bool,
  postAverageRating: PropTypes.number,
  postTotalRatings: PropTypes.number,
};

PostListItem.defaultProps = {
  isMetadataFooterVisible: true,
  isMetadataHeaderVisible: true,
  canSeeMoreButton: () => false,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const isRTL = getIsRTL(state);
  const contentAlignment = getContentAlignment(state, isRTL);

  return {
    isRTL,
    isFeedMoreActionsFixEnabled: isExperimentEnabled(
      state,
      EXPERIMENT_FEED_MORE_ACTIONS_FIX,
    ),
    shouldUsePostListMobileDescriptionFontSize:
      getShouldUsePostListMobileDescriptionFontSize(state),
    shouldUsePostListMobileTitleFontSize:
      getShouldUsePostListMobileTitleFontSize(state),
    post: ownProps.post || getPostByIdOrSlug(state, ownProps.postId),
    descriptionLineCount: getLayoutDescriptionLineCount({ state }),
    titleLineCount: getLayoutTitleLineCount({ state }),
    navigateProGalleryWithinPostPage: actions.navigateProGalleryWithinPostPage,
    alignment: getContentAlignmentStyles(contentAlignment, isRTL),
    postAverageRating: getPostAverageRating(state, resolveId(ownProps.post)),
    postTotalRatings: getPostTotalRatings(state, resolveId(ownProps.post)),
  };
};

export default flowRight(
  withFontClassName,
  withPostFontSize,
  withFeedMetadataSettings,
  withIsFeedDesignEnabled,
  withFeedBorderWidth,
  withDeviceType,
  connect(mapRuntimeToProps),
)(PostListItem);
